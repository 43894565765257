<template>
  <!-- 渲染多个（单个？）不同业务组件 -->
  <div 
    v-if="list.length"
    class="cccx-comp-container"
  >
    <template v-for="(data, index) in list">
      <div
        :id="`comp-${index}-${data.styleType}-${channelId}`"
        :key="`${data.id}_${index}`"
        @click="handleCompClick(index, data.styleType, data.blockKey)"
      >
        <component
          :is="data.__comp_name"
          :ref="`comp-${data.styleType}-${channelId}-${index}`"
          :context="context"
          :content="content"
          :scene-data="sceneData"
          :prop-data="data"
          :index="index"
          :data-component-name="data.props && data.props.name"
          :language="language"
          :brand="brand"
          :is-last="list.length === index + 1"
          :lazy-force-show="index < forceRenderNumber"
          :lazy-wrap-options="getLazyWrapOptions(index, data)"
          :when-visible="isVisibleHydratedComp(data)"
          :trigger-hydration="!isVisibleHydratedComp(data)"
          :never="index < forceRenderNumber && clientRender"
          :prop-hooks="hooks?.[data.__comp_name.toLocaleUpperCase()]"
          :active-channel-id="activeChannelId"
          @baseImageMounted="baseImageMounted()"
        >
          <template
            v-for="(_, name) in $slots"
            #[name]
          >
            <slot :name="name"></slot>
          </template>
          <template
            v-for="(_, name) in $scopedSlots"
            #[name]="props"
          >
            <slot 
              :name="name"
              v-bind="props"
            ></slot>
          </template>
        </component>
      </div>
    </template>
  </div>
</template>

<script>

import components from '../common/comp-bff'
import { compMapping, CATE_TYPE } from '../common/comp-bff-map'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { analysisPlugin } from '@shein-aidc/utils-cccx'
import Vue from 'vue'
import { cccxEventBus, handleObjIsFrozen } from 'public/src/pages/components/ccc/common/utils.js'

const SHEIN_ALL_CHANNELID = {
  'central': 25,
  'usa': 23,
  'eur': 24,
}

daEventCenter.addSubscriber({ modulecode: '2-22' })
const daEventExpose = daEventCenter.getExposeInstance()
Vue.use(analysisPlugin)
export default {
  name: 'BffApp',
  components,
  props: {
    /**
     * App Ccc 环境数据（网站的环境变量等）！！！
     * @param {String} lang [必须] 语言
     * @param {String} SiteUID [必需] 站点UID
     * @param {String} PUBLIC_CDN [非必需，尽量提供] 影响图片的CDN，否则会回源
     * @param {String} WEB_CLIENT [必需] 影响图片展位的样式
     * @param {Number} serverTime [非必需] 服务端直出的时间戳
     * @param {String} forceRenderNumber [非必需] 一个让组件懒加载强制渲染的字段
     * @param {String} dateLangMap 日期多语言，TODO：将多语言移动到 language 对象中
     * @param {Object} language 多语言，看情况，目前需要手动传入渲染的组件多语言，如果只有图片不需要传入多语言，TODO：未来需要做成根据渲染的组件拉取多语言
     * @param {Object} IMG_LINK [非必需] 直出商品组件必传
     * @param {Object} LAZY_IMG_PIXEL [非必需] 直出商品组件必传
     * @param {Object} LAZY_IMG [非必需] 直出商品组件必传
     * @param {Object} LAZY_IMG_SQUARE [非必需] 直出商品组件必传
     * @param {Object} GB_cssRight [非必需] 直出商品组件必传
     * @param {Object} langPath [非必需] 直出商品组件必传
     * @param {Object} rankInfo TODO: 需要删掉不能放在context里面
     * @param {Number} id TODO: 页面数据的id，不应该放在context，待整改
     * @param {Object} content TODO: 待整改
     *    @param {String} abtParams TODO: 待整改
     *    @param {Object} extend TODO: 待整改
     * @param {Number} activeChannelId 当前频道
     */
    context: {
      type: Object,
      default: () => ({})
    },
    /**
     * ccc 数据列表
     */
    content: {
      type: Array,
      default: () => []
    },
    // 当前频道下的组件abt
    abtInfoObj: {
      type: Object,
      default: () => ({})
    },
    /**
     * ccc 的特殊链接
     */
    // cateLinks: {
    //   type: Object,
    //   default: () => ({})
    // },
    /**
     * 场景数据，比如场景实验，标识符等（可理解为当前列表应用在那个场景对应的一些公共配置  约等于 页面配置）
     * @param {String} pageFrom [必需] 当前列表应用在哪个页面（前端自己定义的字段，目的是来组件中进行区分）
     * @param {String} id [非必需] 当前数据的ccc配置的id，从接口出来
     * @param {String} pageType [非必需 - 埋点需要] CCC 数据带过来的页面类型
     * @param {String} sceneName [非必需 - 埋点需要] 场景场景名称 - 比如，首页的women，专题叫做campaign
     * 
     * --首页场景 S--
     * @param {String} poskey [非必需 - 埋点需要] 场景实验 - 比如，精投，或者首页频道对应的实验
     * @param {String} tabName [非必需 - 埋点需要] tab频道名称 - 首页的切tab频道
     * @param {String} isFirstChannel [非必需] 首页业务需要知道是否是第一个tab
     * @param {String} channelInfo [非必需] 首页业务需要用到当前频道对象内部的各种数据
     * --首页场景 E--
     * 
     * --专题场景 S--
     * @param {String} identity [非必需] 专题标识符
     * @param {String} activityId [非必需 - 埋点需要] 页面id - 专题id
     * @param {String} activityNameEn [非必需 - 埋点需要] 页面标识符 - 专题的标识符
     * --专题场景 E--
     */
    sceneData: {
      type: Object,
      default: () => ({})
    },
    /**
     * 组件场景差异钩子
     * key: 组件名称（在comp中对应组件的名称.toLocaleUpperCase()）
     * value: any，可以是任意类型，会在组件中通过props的方式传入给对应的组件
     * // 以下只是例子并不是真实的hooks
     * eg: {
     *  HORIZONTALITEMS: {
     *    getHorizontalList: () => {},
     *  },
     *  VERTICALITEMS: 'SheinText'
     * }
     */
    hooks: {
      type: Object,
      default: () => ({})
    },
    activeChannelId : {
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      isLoad: false,
      emptyObject: {},
      firstScreenNumber: this.sceneData?.pageFrom === 'campaigns' ? 8 : this.context?.forceRenderNumber || 4,
    }
  },
  computed: {
    isHomePage() {
      return this.sceneData?.pageType === 'homePage'
    },
    channelId() {
      return this.context?.channelId || ''
    },
    clientRender() {
      return this.context?.clientRender || false
    },
    forceRenderNumber() {
      return this.context?.forceRenderNumber || 4
    },
    language() {
      return this.context?.language
    },
    brand() {
      return this.context?.WEB_CLIENT || 'shein'
    },
    list() {
      return handleObjIsFrozen(this.content).filter((item, index) => {
        if (!compMapping(item)) return false
        item.__comp_name = compMapping(item)
        // TODO: 向前兼容，所以定义两个值，后续统一使用firstScreen
        item.isFirstPage = index < this.firstScreenNumber
        item.firstScreen = index < this.firstScreenNumber
        return true
      })
    },
  },
  activated() {
    this.resetExpose()
  },
  async mounted(){
    window.addEventListener('load', () => {
      // this.isLoad = true
      cccxEventBus.emit('videoEvent')
      window.windowIsLoad = true
    })
    setTimeout(() => {
      // this.isLoad = true
      cccxEventBus.emit('videoEvent')
      window.windowIsLoad = true
    }, 5000)
  },
  methods: {
    handleCompRenturnTopAbt(styleType, blockKey) {
      const abtInfoObj = this.context?.abtInfoObj || {}
      const isHitReturnTop = (abtInfoObj?.HomePageReturnTopping_All === 'true' && this.channelId == SHEIN_ALL_CHANNELID[gbCommonInfo.SERVER_TYPE]) || (abtInfoObj?.HomePageReturnTopping_Other === 'true' && this.channelId != SHEIN_ALL_CHANNELID[gbCommonInfo.SERVER_TYPE])
   
      let isInBackList = false
      // 组件的 blockKey 可能为空
      if (this.channelId === SHEIN_ALL_CHANNELID[gbCommonInfo.SERVER_TYPE]) {
        abtInfoObj?.ReturnToppingBlackBlocks_All?.split('/')?.some(item => {
          if (item !== '' && item === blockKey) {
            isInBackList = true
            return true
          }
        })
        abtInfoObj?.ReturnToppingBlackComponents_All?.split('/')?.some(item => {
          if (item === styleType) {
            isInBackList = true
            return true
          }
        })
      } else {
        abtInfoObj?.ReturnToppingBlackBlocks_Others?.split('/')?.some(item => {
          if (item !== '' && item === blockKey) {
            isInBackList = true
            return true
          }
        })
        abtInfoObj?.ReturnToppingBlackComponents_Others?.split('/')?.some(item => {
          if (item === styleType) {
            isInBackList = true
            return true
          }
        })
      }

      return !isHitReturnTop || isInBackList
    },
    handleCompClick(compIndex, styleType, blockKey) {
      const ssrComp = 8
      // 非首屏组件不记录点击
      if (compIndex >= ssrComp) return
      // this.$route.query?.returnToTop 当实验不生效时，方便调式
      // 沉浸式组件、免邮组件不记录点击
      const isNotRecord = this.handleCompRenturnTopAbt(styleType, blockKey)
      const noNeedRecord = isNotRecord || styleType === 'FREE_SHIPPING_COMPONENT' || styleType === 'IMMERSIVE_BANNER'
      sessionStorage.setItem('isNotRecord', noNeedRecord)
      if (noNeedRecord && !this.$route.query?.returnTop) return
      // 通知 Home.vue 记录点击组件
      cccxEventBus.emit('click-comp', { compIndex, styleType })
    },
    getLazyWrapOptions(index, data) {
      return index >= this.forceRenderNumber || this.isCustomComp(data) ? this.getComponentHeight(data) : this.emptyObject
    },
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    isCustomComp(data) { // 针对于组件判断是否加载完成然后去掉占位高度
      const { __comp_name } = data || {}
      return ['Countdown'].includes(__comp_name)
    },
    isVisibleHydratedComp(data) { // 基于视口的懒水合组件
      const { __comp_name, styleType, props, blockKey } = data || {}
      const arr = ['CartIcon']
      if (styleType === 'ONE_IMAGE_COMPONENT' && props?.metaData?.enableFloatDisplayAfterReceive) arr.push('StaticImageCardController')
      if (styleType === 'PRODUCT_RECOMMEND_FLOW' && blockKey === 'shoppingCartRecommend') arr.push('RecommendFlow')
      return !arr.includes(__comp_name)
    },
    getComponentHeight (data) {
      const { __comp_name, styleType, componentKey, props = {} } = data || {}
      const { items = [], name = '' } = props
      const common = {
        attrs: {
          'data-component-name': name
        }
      }
      try {
        // 针对商品组件/优惠券/品类推荐（待补充）
        const heightMap = {
          'HorizontalItems': '5.0667rem',
          'FreeShippingInfo': '1.45rem',
          'HorizontalCoupon': '3.2533rem',
          'VerticalCoupon': 74 * items.length + 'px',
          'HorizontalAnchor': '1.1733rem', // 文字锚点
          'Video': '6rem', // 视频组件 先给个固定值 看后续能不能推动添加视频比例配置项才能做准确占位
          'Welfare': '3.17333rem', // 福利组件
          'AZBrandList': '560px', // az字母列表组件 估计一屏高度
          'TextEdit': '150px',
          'BrandPavilionSideSlip': '8.1333rem', // 品牌组件
          'StoreMultiSlide': '5.92rem', // 多店铺横划组件
          'HorizontalItemsDynamic': '4.6133rem', // 横向商品组件(动态)
          'UniversalSearch': '1.013rem', // 通用搜索组件
          'BrandNewusers': '3.093rem', // 新人活动入口组件
          'CategorySlide': '10.1061rem', // 品牌品类轮播组件
          'MultiLine': '4.5333rem',// 灵活布局组件
        }

        if (['UP_COUNTDOWN', 'LEFT_COUNTDOWN'].includes(styleType)) {
          Object.assign(heightMap, {
            'Countdown': styleType === 'UP_COUNTDOWN' ? '2.4rem' : '1.3333rem'
          })
        }


        switch (componentKey) {
          case 'FLASH_SALE':
            {
              const { metaData = {} } = data?.props || {}
              const { topBannerImage = {}, isShowBanner } = metaData
              Object.assign(heightMap, {
                'FlashSale': 6.0267 + (isShowBanner && topBannerImage?.ratio ? `${10 / topBannerImage?.ratio}` : '') + 'rem',
              })
            }
            break
          case 'CATEGORY_RECOMMEND_COMPONENT':
            {
              // 品类推荐
              const { metaData, styleType } = data?.props
              const { rows = 1 } = metaData || {}
              const height = CATE_TYPE[styleType] > 2 ? '1.7867' : '1.44'

              Object.assign(heightMap, {
                'CategoryRecommend': height * rows + 'rem',
              })
            }
            break
          case 'RANKING_LIST_COMPONENT':
            {
              if(__comp_name === 'RankingEntrance') {
                const headerHeight = 0.48 + 0.27
                const paddingHeight = 0.43 + 0.32
                const { rankListInfo = [] } = data?.props?.metaData || {}
                let contentHeight = 0
                if (rankListInfo.length > 1) {
                  contentHeight = 0.32 + 0.37 + 0.32 * 2 + 3.25 + 0.16 + 0.11 + 0.3733
                } else {
                  contentHeight = 0.21 + 0.32 + 3.25 + 0.16 + 0.11 + 0.373333
                }
                Object.assign(heightMap, {
                  'RankingEntrance': contentHeight + headerHeight + paddingHeight + 'rem',
                })
              } else if(__comp_name === 'RankingEntranceList2') {
                const headerHeight = 0.2133 * 2 + 0.48
                const { rankListInfo = [] } = data?.props?.metaData || {}
                let contentHeight = 0
                let outerGap = 0
                if(rankListInfo.length === 1) {
                  outerGap = 0.1067 + 0.2667
                  contentHeight = 0.2133 + 0.16 + 0.3733 + 0.08 + 0.1067 + 0.32 + (10 - 0.2133 * 2 - 0.1067 * 2 ) * 0.239 * 1.33
                } else if(rankListInfo.length === 2) {
                  outerGap = 0.1067 + 0.2667
                  contentHeight = 0.2133 + 0.16 + 0.3733 + 0.16 + 0.1067 + 0.32 + ((10 - 0.2133 * 2) * 0.48 - 0.1067 * 2 ) * 0.4829 * 1.33
                } else if(rankListInfo.length >= 3) {
                  outerGap = 0.2667
                  contentHeight = 0.1067 + 0.2133 + 0.16 + 0.3733 + 0.16 + 0.1067 + ((10 - 0.2133 * 2 - 0.1067 * 2 ) / 2.1 - 0.1067 * 3) * 0.4829 * 1.33
                }
                Object.assign(heightMap, {
                  'RankingEntranceList2': contentHeight + headerHeight + outerGap + 'rem',
                })
              }
              // RankingEntranceList2Home
            }
            break
          case 'SUBSCRIBE_COMPONENT':
            break
          case 'SURVEY_COMPONENT':
            {
              // 意见收集组件
              const { isAllowMultiCommit = false } =  data?.props?.metaData || {}
              const isHasCommit = data?.survey || false // 其实获取不到 用户是否已经提交过问券
              if (isHasCommit) {
                Object.assign(heightMap, {
                  'Survey': (isAllowMultiCommit ? 4.6667 : 3.8667) + 'rem',
                })
              } else {
                const agree = 4.50 // 提交按钮 + 协议高度
                const quesMap = {
                  '1': 2.72,    // 问题1 
                  '2': 4.76,    // 问题2
                  '3': 2.0267,  // 问题3 单个 1.44 最大 12.1333
                  '4': 2.0267,  // 问题4 单个 1.44 最大 12.1333
                  '5': 12.9771, // 问题5
                  '6': 12.9771, // 问题6
                  '7': 3.1467,  // 问题7
                }
                const number = (items || []).reduce((prev, cur) => {
                  const base = quesMap?.[String(cur?.quesType)]
                  let other = 0
                  if (cur?.quesType === 3 || cur?.quesType === 4) {
                    other = 1.44 * ((cur?.quesOption || []).length || 0)
                    other = other > 12.1333 ? 12.1333 : other
                  }
                  return base + other + prev
                }, 0)
                Object.assign(heightMap, {
                  'Survey': agree + number + 'rem',
                })
              }
            }
            break
          case 'PK_COMPONENT':
          {
            const { mainTitle } =  data?.props?.metaData || {}
            let imageHeight = 5.3333
            let bottomHeight = 0.32 + 1.5434
            const items = data?.props?.items || []
            const maxImgRatio = Math.min(items[0]?.image?.ratio, items[1]?.image?.ratio)
            if (styleType === 'PK') {
              imageHeight = 4 / maxImgRatio // 图片宽度4rem, 高度按图片比例计算
              bottomHeight = 0.32 + 1.653   // 图片距离底部剩余高度
            } else if (styleType === 'PK_STATIC') {
              imageHeight = 4.6 / maxImgRatio
              bottomHeight = 0.32 + 0.853 + 0.64
            }
            const titleHeight = mainTitle ? 2.08 : 1.4933 // pk标题占位
            Object.assign(heightMap, {
              'PK': titleHeight + imageHeight + bottomHeight + 'rem'
            })
            break
          }
          case 'SHOPLIST_COMPONENT':
          {
            const { showShopStyleImage } =  data?.props?.metaData || {}
            let imageHeight = 5.3333
            let compName = 'StoreCardList'
            if (styleType === 'SHOPLIST_COMPONENT_HORIZONTAL') {
              imageHeight = showShopStyleImage ? 14.3467 : 5.3333
              compName = 'StoreHorizontalCardList'
            } else if (styleType === 'SHOPLIST_COMPONENT_VERTICAL') {
              imageHeight = showShopStyleImage ? 8.8 : 5.68
            }
            Object.assign(heightMap, {
              [compName]: imageHeight * items?.length + 'rem'
            })
            break
          }
          case 'SHOPCARD_COMPONENT':
          {
            const { showShopStyleImage } =  data?.props?.metaData || {}
            let imageHeight = 5.5733
            if (styleType === 'SHOPCARD_COMPONENT_ONE') {
              imageHeight = showShopStyleImage ? 9.9733 : imageHeight
            }
            Object.assign(heightMap, {
              'StoreSingleCard': imageHeight * items?.length + 'rem'
            })
            break
          }
          case 'BRAND_COMPONENT': 
          {
            const styleType = data?.styleType
            const items = data?.props?.items || []
            if (styleType === 'BRAND_WALL') {
              let height = 10
              if (data?.props?.metaData?.mainTitle) {
                height += 34
              }
              if (items?.[0]?.items?.length) {
                height += 57
                if (items?.[1]?.items?.length) {
                  height += 80
                }
              }
              Object.assign(heightMap, {
                'BrandWall': height * 2 / 75 + 'rem'
              })
            }
            break
          }
          case 'HEADER_COMPONENT':
          {
            const metaData = data?.props?.metaData || {}
            let height = 87
            // 有轮播图
            if (metaData.backgroundImages.length > 0) {
              height = 320
            }
            // 无轮播图（有tab）
            if (metaData.showCategory || metaData.showTab) {
              height = 131
            }
            // 无轮播图（有usp）
            if (metaData.showUSP) {
              height = 159
            }
            Object.assign(heightMap, {
              'BrandHeader': (height - 44) / 37.5 + 'rem'
            })
            break
          }
        }
        if (styleType === 'DAILYNEW_ITEMS') {
          // dailynew商品
          const { rows, isShowViewAll = false } =  data?.props?.metaData || {}
          Object.assign(heightMap, {
            'DailyNewItems': (1.76 + (isShowViewAll ? 1.3066 : 0) + (4.8064 * rows)) + 'rem',
          })
        }

        if(styleType === 'HORIZONTAL_ITEMS') {
          // 静态 - 横向
          const itemLength = data?.props?.items?.length || 1
          let height = 193

          if(itemLength > 1) {
            height += 44
          }
          
          Object.assign(heightMap, {
            'HorizontalItems': (height) / 37.5 + 'rem'
          })
        }
        
        if(styleType === 'HORIZONTAL_ITEMS_DYNAMIC') {
          // 动态 - 横向 140 + 32 
          const itemLength = data?.props?.items?.length || 1
          let height = 140

          if(itemLength > 1) {
            height += 32
          }
          
          Object.assign(heightMap, {
            'HorizontalItemsDynamic': (height) / 37.5 + 'rem'
          })
        }

        if(styleType === 'VERTICAL_ITEMS') {
          // 纵向商品组件
          let rowsLength = 0
          const { rows, cols = 3, isShowTab } = props.metaData
          const { limitNum, isSelfConfigImage, selfConfigImageLists = [], isFrontEndSorting } = items?.[0] || {}
          if(isSelfConfigImage) {
            const placeHolderNum = selfConfigImageLists.length
            rowsLength = Math.ceil(placeHolderNum / cols)
          } else {
            rowsLength = rows || Math.ceil((limitNum || 40) / cols)// 展示多少行商品
          }
          const tabHeight = isShowTab ? 1.17 : 0
          const sortHeight = isFrontEndSorting ? 1.0667 : 0
          const gapBottom = cols === 3 ? 0.16 : 0.3733 
          const imgHeight = cols === 3 ? 2.9733 * 1.33 : ((4.595 - 0.213) * 1.33 + 0.213)
          const itemHeight = imgHeight + 0.16 + 0.496 + gapBottom
          Object.assign(heightMap, {
            'VerticalItems': itemHeight * rowsLength + tabHeight + sortHeight + 'rem',
          })
        }

        // 设计师投票组件
        if(styleType === 'SHEIN_X_VOTE') {
          const { layoutType = 'tile' } = props.metaData
          const statusHeight = 0.96
          const sortHeight = 1.44
          const designerListHeight = layoutType === 'tile' ? (8.32 * 10) : (9.2267 * 5)
          const totalHeight = statusHeight + sortHeight + designerListHeight
          Object.assign(heightMap, {
            'SheinXVote': totalHeight + 'rem',
          })
        }

        // 转盘抽奖组件
        if(styleType === 'DRAW_SPIN') {
          const { userPrizeRecordList = [], allUserPrizeRecordList = [] } = data?.drawLottery?.data?.lotteryRecord || {}
          const userPrizeRecordNum = userPrizeRecordList?.length || 0
          const userPrizeRecordHeight = userPrizeRecordNum > 0 ? userPrizeRecordNum * 0.5333 + 1.44 : 0 // 我的奖品列表高度
          const allUserPrizeRecordHeight = allUserPrizeRecordList?.length > 0 ? 0.6133 : 0 // 所有奖品列表高度
          Object.assign(heightMap, {
            'DrawSpin': `${9.0933 + userPrizeRecordHeight + allUserPrizeRecordHeight}rem`,
          })
        }

        // 新闻板块组件
        if(styleType === 'NEWS_SECTION') {
          const bottomSpace = props.metaData?.disableBottomSpacing ? 0 : 0.2133
          const sectionSpace = (items.length - 1) * 0.2133
          let sectionListHeight = 0
          items.forEach(sectionItem => {
            sectionListHeight += 0.7467 + sectionItem?.items?.length * 2.7733
          })
          Object.assign(heightMap, {
            'NEWS_SECTION': `${sectionListHeight + sectionSpace + bottomSpace}rem`,
          })
        }

        if (heightMap[__comp_name]) {
          return {
            ...common,
            style: {
              'min-height': heightMap[__comp_name]
            }
          }
        }
        // 针对图片组件
        if (['IMAGE_CAROUSEL_COMPONENT', 'IMAGE_COMPONENT', 'ANCHOR_COMPONENT', 'COUNTDOWN_COMPONENT', 'CODE_IMAGE_COMPONENT'].includes(componentKey)) {
          const { height, width } = items?.[0]?.image || items?.[0]?.items?.[0]?.image || items?.[0]?.imgSrc || {}
          let paddingBottom = ((height / width) * 100).toFixed(2) + '%'
          if (componentKey === 'IMAGE_CAROUSEL_COMPONENT') {
            const paddingMap = {
              ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE: 2.5,
              ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE: 3.5,
              ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE: 4.5,
              ITEM_IMAGE_FOUR_COLS_CAROUSEL: 4,
              ITEM_IMAGE_FIVE_COLS_CAROUSEL: 5,
              MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC: 4,
              MULTI_ITEMS_CAROUSEL_IND_DYNAMIC: 4,
              MULTI_ITEMS_CAROUSEL_CHANNEL_ENTRANCE_DYNAMIC: 3.5,
              MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC: 3.5,
            }
            if (paddingMap[styleType]) {
              paddingBottom = ((height / width) * 100 / paddingMap[styleType]).toFixed(2) + '%'
            }
          }
          return {
            ...common,
            style: {
              paddingBottom
            }
          }
        }
        return { ...common }
      } catch (error) {
        console.log(error)
        return { ...common }
      }
    },
    resetExpose() {
      this.sceneData?.pageFrom && daEventExpose?.resetAll?.(this.sceneData.pageFrom)
    },
  },
}
</script>
